import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage from '../HomePage'
import Homepage2 from '../HomePage2/HomePage2'
import HomePage3 from '../HomePage3/HomePage3';
import HomePage4 from '../HomePage4/HomePage4';
import AboutPage from '../AboutPage/AboutPage';
import WeddingPage from '../WeddingPage/WeddingPage';
import WeddingDetailsPage from '../WeddingDetailsPage/WeddingDetailsPage';
import GalleryPage from '../GalleryPage/GalleryPage';
import RSVPPage from '../RSVPPage/RSVPPage';
import BlogPage from '../BlogPage'
import BlogPageLeft from '../BlogPageLeft'
import BlogPageFullwidth from '../BlogPageFullwidth'
import BlogDetails from '../BlogDetails'
import BlogDetailsFull from '../BlogDetailsFull'
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide'
// import ErrorPage from '../ErrorPage'
// import ComingSoonPage from '../ComingSoonPage/ComingSoonPage'
// import LoginPage from '../LoginPage'
// import SignUpPage from '../SignUpPage'
// import ForgotPassword from '../ForgotPassword'

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='home' element={<Homepage />} />
          <Route path='home2' element={<Homepage2 />} />
          <Route path='home3' element={<HomePage3 />} />
          <Route path='home4' element={<HomePage4 />} />
          <Route path='about' element={<AboutPage />} />
          <Route path='wedding' element={<WeddingPage />} />
          <Route path='wedding-details' element={<WeddingDetailsPage />} />
          <Route path='gallery' element={<GalleryPage />} />
          <Route path='rsvp' element={<RSVPPage />} />
          <Route path='blog' element={<BlogPage />} />
          <Route path='blog-left-sidebar' element={<BlogPageLeft />} />
          <Route path='blog-fullwidth' element={<BlogPageFullwidth />} />
          <Route path='blog-single/:slug' element={<BlogDetails />} />
          <Route path='blog-single-left-sidebar/:slug' element={<BlogDetailsLeftSiide />} />
          <Route path='blog-single-fullwidth/:slug' element={<BlogDetailsFull />} />
          {/* 
          <Route path='login' element={<LoginPage />} />
          <Route path='register' element={<SignUpPage />} />
          <Route path='forgot-password' element={<ForgotPassword />} /> */}
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
